import React from "react"
import styled from "styled-components"
import { Heading, ProgressCircle } from "../../components/components"

const Statistic = () => {
  return (
    <Flex>
      <Wrapper>
        <ProgressCircle displayedValue={7} />
        <Heading tag="h4">Jahre Projekterfahrung</Heading>
      </Wrapper>

      <Wrapper>
        <ProgressCircle displayedValue={15} />
        <Heading tag="h4">umgesetzte Projekte</Heading>
      </Wrapper>
      <Wrapper>
        <ProgressCircle displayedValue={10} />
        <Heading tag="h4">erfahrene Kollegen</Heading>
      </Wrapper>
    </Flex>
  )
}

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;

  @media only screen and (max-width: 528px) {
    gap: 2rem;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 528px) {
    > div {
      > div {
        height: 4rem;
        width: 4rem;
        svg {
          display: none;
        }
        p {
          font-size: 2.5rem;
        }
      }
    }
  }
`

export default Statistic
