import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import { useTheme } from "../../styles/themes"
import { graphql, useStaticQuery } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import { AllContentfulCustomerQuery } from "../../../generated/gatsby-graphql"
// import "swiper/css"

const CompanyPortfolio = () => {
  const { currentThemeName } = useTheme()
  const { allContentfulCustomer } =
    useStaticQuery<AllContentfulCustomerQuery>(query)

  const { currentBreakpoint } = useTheme()

  const slidesPerView = () => {
    switch (currentBreakpoint.name) {
      default:
        return 5
      case "xxl":
        return 5
      case "xl":
      case "lg":
        return 4
      case "md":
        return 3
      case "sm":
        return 2
    }
  }

  return (
    <Wrapper>
      <Swiper
        loop
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        spaceBetween={50}
        slidesPerView={slidesPerView()}
      >
        {allContentfulCustomer.nodes.map((entry, i) => {
          if (!entry.logo) {
            return null
          }

          return (
            <SwiperSlide key={i}>
              <ItemWrapper isDark={currentThemeName === "dark"}>
                <ImageContainer
                  alt="Logo eines Kunden"
                  image={
                    getImage(entry.logo.gatsbyImageData) as IGatsbyImageData
                  }
                />
              </ItemWrapper>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  margin-top: 2rem;
  max-width: 1280px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.md} {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
`

// const query = graphql`
//   query {
//     companies: allFile(filter: { relativeDirectory: { in: "company" } }) {
//       images: nodes {
//         image: childImageSharp {
//           gatsbyImageData(width: 110, layout: FIXED, placeholder: BLURRED)
//         }
//       }
//     }
//   }
// `

// sorted by newest first
const query = graphql`
  query allContentfulCustomer {
    allContentfulCustomer(sort: { fields: createdAt, order: DESC }) {
      nodes {
        createdAt
        logo {
          gatsbyImageData(width: 110, layout: FIXED, placeholder: BLURRED)
        }
      }
    }
  }
`

export default CompanyPortfolio

const ItemWrapper = styled.div<{ isDark: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  padding: 1rem;
  border-radius: ${props => props.theme.borderRadius};
  background: ${props =>
    props.isDark ? props.theme.palette.text.vibrant : "transparent"};

  /* width: 5rem; */
`

const ImageContainer = styled(GatsbyImage)`
  display: flex;
  width: 100%;
  padding: 16px;
  filter: grayscale(1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    filter: grayscale(0);
  }
`
