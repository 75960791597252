import { graphql } from "gatsby"
import React from "react"
import {
  ButtonLink,
  Column,
  Container,
  Head,
  Heading,
  Emphasized,
  ImageLayer,
  Paragraph,
  Row,
  SecondarySection,
  Section,
  Spacer,
} from "../../components/components"
import CallToAction from "../../widgets/CallToAction/CallToAction"
import CompanyPortfolio from "../../widgets/CompanyPortfolio/CompanyPortfolio"
import Statistic from "../../widgets/Statistic/Statistic"

const CompanyOverview = ({ data }: { data: any }) => {
  return (
    <>
      <Head pagePath="/ueber-uns" />
      <Section>
        <Container style={{ alignItems: "center" }}>
          <Row style={{ margin: 0 }}>
            <Column
              style={{
                alignItems: "center",
              }}
            >
              <Heading tag="h1" style={{ textAlign: "center" }}>
                Ihre <Emphasized> Experten</Emphasized> für digitale Lösungen
              </Heading>
            </Column>
          </Row>
          <Spacer gutter={16} />
          <Row style={{ margin: 0 }}>
            <Column lg={12}>
              <Statistic />
            </Column>
          </Row>
        </Container>
      </Section>
      <SecondarySection>
        <Container style={{ alignItems: "center" }}>
          <Row style={{ margin: 0 }}>
            <Column
              lg={12}
              style={{
                alignItems: "center",
              }}
            >
              <ImageLayer
                alt="Incoqnito Mitarbeiter sitzt an einem Schreibtisch und programmiert"
                color="purple"
                image={data.company1.image}
              >
                <Heading tag="h1">Die Incoqnito GmbH</Heading>
                <Paragraph>
                  Wir, die Incoqnito GmbH, sind eine inhabergeführte Software-
                  und Beratungsagentur aus Hannover. Als digital natives
                  entwerfen, entwickeln und betreuen wir individuelle und
                  leidenschaftlich gestaltete Softwarelösungen.
                  <br />
                  <br />
                  In unserem multidisziplinären Team aus Softwareentwicklern,
                  UI-/UX-Experten, Webdesignern, Projektmanagern sowie Analysten
                  können wir auf langjährige Erfahrung und technologische
                  Expertise zurückgreifen, um komplexe Projekte einfach
                  umzusetzen.
                  <br />
                  <br />
                  Unsere Mission ist es, als langjähriger Partner für unsere
                  Kunden flexible, skalierbare und zukunftsfähige Anwendungen zu
                  entwickeln, die ihr Geschäft noch weiter wachsen lassen – und
                  nachhaltigen Mehrwert liefern.
                  <br />
                  <br />
                  Wir sind der Überzeugung, dass die Kombination aus Expertise
                  und Leidenschaft die treibende Kraft hinter jedem
                  erfolgreichen Projekt ist. Daher vertrauen bereits zahlreiche
                  namhafte Unternehmen aus den verschiedensten Branchen auf
                  unsere Erfahrung.
                </Paragraph>
              </ImageLayer>
            </Column>
          </Row>
          <Row
            style={{
              margin: 0,
              padding: "24px 0",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Column>
              <ImageLayer
                alt="Unser CTO bedient ein Tablet. Sein Kollege neben ihm schaut auf das Tablet."
                color="blue"
                inverted
                image={data.company2.image}
              >
                <Heading tag="h1">Unsere Vision</Heading>
                <Paragraph>
                  Es ist unsere Vision, eine außergewöhnliche Organisation zu
                  schaffen, in der alle Beteiligten gerne arbeiten und die
                  Meinung eines jeden zählt, während wir als Team gemeinsam
                  etwas Erfolgreiches aufbauen. Dieser Erfolg soll jedoch nicht
                  ausschließlich dem Zweck dienen, unsere existenziellen
                  Bedürfnisse zu befriedigen. Es ist unser Antrieb, noch weiter
                  zu gehen und die Ideen, Mittel und Möglichkeiten unseres
                  Unternehmens zu nutzen, um die Welt um uns herum ein Stück
                  besser machen zu können.
                </Paragraph>
              </ImageLayer>
            </Column>
          </Row>
        </Container>
      </SecondarySection>
      <Section>
        <Container style={{ alignItems: "center" }}>
          <Row
            style={{
              margin: 0,
              padding: "24px 0",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Column>
              <ImageLayer
                alt="Ein Incoqnito Mitarbeiter und unser CEO schauen gemeinsam auf ein Tablet"
                color="red"
                image={data.company3.image}
              >
                <Heading tag="h1">Unsere Kultur</Heading>
                <Paragraph>
                  Seit dem ersten Tag ist unsere Kultur der Eckpfeiler unseres
                  Unternehmens. Sie hat uns zu dem gemacht, was wir sind und
                  prägt unser alltägliches Miteinander. Wir setzen auf eine
                  werteorientierte Unternehmenskultur, die uns als Team
                  zusammenschweißt. Es ist unser festes Ziel, unseren
                  Mitarbeitern ein Arbeitsumfeld zu bieten, in welchem sie sich
                  selbst verwirklichen und stetig weiterentwickeln können – ohne
                  Grenzen nach oben. Wir entwickeln gerne neue Ideen, testen
                  diese schnell aus und möchten zusammen etwas auf die Beine
                  stellen.
                </Paragraph>
                <ButtonLink arrowButton red to="/karriere">
                  Werde Teil des Teams
                </ButtonLink>
              </ImageLayer>
            </Column>
          </Row>
          <Row
            style={{
              margin: 0,
              padding: "24px 0",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Column>
              <ImageLayer
                alt="Das Incoqnito Team sitzt gemeinsam in einem Besprächungsraum. Zwei Mitarbeiter stehen und präsentieren vor einem Whiteboard."
                color="blue"
                inverted
                image={data.aboutUs.image}
              >
                <Heading tag="h1">Unsere Geschichte</Heading>
                <Paragraph>
                  Wir planen & entwickeln individuelle Software seit 2015.
                  Digitale Lösungen zu kreieren, die für Menschen Mehrwert
                  schaffen, Unternehmen wachsen lassen und bestehende
                  Herausforderungen meistern, ist für uns und unsere Kunden
                  immer der Antrieb gewesen.
                </Paragraph>
              </ImageLayer>
            </Column>
          </Row>
        </Container>
        <Container>
          <CompanyPortfolio />
        </Container>
      </Section>
      <CallToAction type="customer" />
    </>
  )
}

export default CompanyOverview

export const query = graphql`
  query {
    company1: file(relativePath: { eq: "stock/company1.jpg" }) {
      image: childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
    company2: file(relativePath: { eq: "stock/company2.jpg" }) {
      image: childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
    creation: file(relativePath: { eq: "backgrounds/adams.png" }) {
      image: childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
    company3: file(relativePath: { eq: "stock/company3.jpg" }) {
      image: childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
    aboutUs: file(relativePath: { eq: "stock/aboutUs.jpg" }) {
      image: childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
  }
`
